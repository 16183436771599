body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
button:focus {
    outline: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: Ramblar;
  src: url(/fonts/Rambla-Regular.ttf);
}
@font-face {
  font-family: Ramblab;
  src: url(/fonts/Rambla-Bold.ttf);
}
@font-face {
  font-family: Santander;
  src: url(/fonts/Santander-Headline-W05-Regular.ttf);
}
#root{
  background-image:url(/images/fondo-mobile.jpg);
  background-size:cover;
  padding-top:60px;
  
}
.footer {
    background-color: #ED1C24;
    color: #fff;
    padding: 10px 30px 1px;
    font-size: 10px;
}
.Ramblar{
  font-family:Ramblar;
}
.Ramblab{
  font-family:Ramblab;
}
.Santander{
  font-family:Rambla;
}
.titulo{
  font-size:27px;
  line-height: 33px;
  color:#404041;
}
.textos{
  font-size:17px;
  line-height: 25px;

}
.borde-rojo{
  border-left:solid 15px #ED1C24 !important;
}
.botonchulo {
    background: #ED1C24;
    padding: 7px 10px;
    font-size: 21px;
    color: #fff !important;
    border-radius: 19px;
    max-width: 209px;
    box-shadow: 0px 6px 6px #aba4a4c9;
}
.centrar{
  margin-left:auto;
  margin-right:auto;
  display:block;
  
}
.logo_auto{
  max-width:200px;
  margin-bottom:30px;
}
a{
  text-decoration:none;
  color:#404041 !important;
}

.footer-global {
    margin-top:50px;
    width: 100% !important;
    left: 0px;
    padding: 0px !important;
}
.textsan{
  font-size:13px;
  float:left;
  padding-top:3px;
  text-align:center;
}
.logo_san{

}
.btn-opc {
    background: #ED1C24;
    color: #fff;
    padding-top: 26px;
    padding-bottom: 26px;
    border: none;
    border-radius: 22px;
    width: 138px;
    font-size: 17px;
    margin: 10px;
    float: left;
}
.preguntas{
  font-size:19px;
  margin:10px;
  margin-bottom:25px;
}
.bullet-active{
  background:#ED1C24;
  padding: 5px;
  border-radius: 50%;
  border: none;
}
button.bullet {
    background: #f3efef;
    padding: 5px;
    border-radius: 50%;
    border: none;
    box-shadow: 1px 1px grey;
    margin: 3px;
}
.btn-toolbar {
    width: 102px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.linea {
    background: rgb(252,252,252);
    background: linear-gradient(90deg, rgba(252,252,252,1) 0%, rgba(115,115,131,1) 67%, rgba(215,221,222,1) 100%);
    padding: 1px;
    height: 5px;
    width: 100%;
    margin-bottom: 15px;
}
.respuestas{
  width:316px;
}
li{
  list-style:none;
}
img.chulo {
    width: 8%!important;
    float: left;
}
.text-chulo{
  float:left;
  width:92%!important;
}
.list-check{
  margin-left:-44px; 
}
.botonchulo{
  margin:15px auto;
}
.text-end{
  text-align:center;
}
.padding-2lin {
    padding: 26px 14px;
}
@media only screen and (min-width: 769px){
  #root{
  background-image:url(/images/fondo.jpg);
  background-size:cover;
  padding-top:60px;
  
}
  .center-text-desk{
     text-align:center !important; 
     width:100%;
  }
  img.chulo {
    width: 2%!important;
    float: left;
}
.text-chulo{
  float:left;
  width:98%!important;
}
.text-end{
  text-align:center;
}
.fix-desktop{
    position: absolute;
    bottom: 0px;
    width: 100%;
}
#root{
  min-height:100vh;
}
.list-check{
  margin-left:0px; 
}
.botonchulo{
  margin:10px auto;
}
}

@media only screen and (min-width: 1200px){
  .container {
      width: 738px !important;
  }
}